<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <insecticide-statistics />
  </b-container>
</template>
<script>

import { core } from '@/config/pluginInit'

import InsecticideStatistics from '@/components/dashboard/InsecticideStatistics.vue'

export default {
  name: 'ManagerGraphFungicides',
  middleware: ['auth', 'manager'],

  components: {
    InsecticideStatistics
  },

  metaInfo () {
    return {
      title: 'Insecticides Graph'
    }
  },

  mounted () {
    core.index()
  }
}
</script>
